import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { MessageWarning } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

const GET_MESSAGE = `${API_URL}/cashback/get/message`;

const ACCEPT_WARNING_MSG = `${API_URL}/cashback/approve/message`;

export const useGetMessageWarning = (options: any = {}) => {
  return useQuery<MessageWarning[]>(
    "message-warning",
    async () => {
      const { data } = await axios.get(GET_MESSAGE);
      return data?.messages;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useAcceptWarningMsg = () => {
  return useMutation<any, any, number>({
    mutationFn: async (id) => {
      const response = await axios.post(`${ACCEPT_WARNING_MSG}/${id}`);
      return {
        data: response.data,
      };
    },
  });
};
