import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { Card, Button, Alert, Spinner } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

export const NotificationRegistration = () => {
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex flex-column flex-center text-center ">
        <div className="card-body py-10 py-lg-15">
          <h1 className="fw-bolder text-gray-900 mb-5">Подтвердите email</h1>

          <div className="fs-6 mb-8">
            <span className="fw-semibold text-gray-500">
              Мы отправили вам письмо со ссылкой для подтверждения электронной
              почты. Для активации аккаунта перейдите по ссылке из письма.
            </span>
          </div>

          <div className="mb-0">
            <img
              src={toAbsoluteUrl("/media/auth/please-verify-your-email-dark.png")}
              className="mw-100 mh-300px"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="d-grid">
        <Button variant="primary" size="lg" onClick={() => navigate("/auth")}>
          Вернуться на страницу входа
        </Button>
      </div>
    </>
  );
};
