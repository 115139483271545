/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login, useConfirmLoginTotp } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { useIntl } from "react-intl";
import { ModalLayout } from "../../../../_metronic/partials/modals/ModalLayout";
import { isArray } from "lodash";
import { removeAllSpaces, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { TotpLoginModel } from "../core/_models";

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

type ResponseTotp = {
  session_id: string;
};

const initialValuesMask = ["_", "_", "_", "_", "_", "_"];
const regex = /^[0-9]+$/;

export function Login() {
  const [inputMaskValues, setInputMaskValues] = useState(initialValuesMask);
  const [inputValue, setInputValue] = useState("");
  const [inputMaskBg, setInputMaskBg] = useState<boolean | null>(null);
  const [previousValue, setPreviousValue] = useState("");
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [indexPass, setIndexPass] = useState<number>(0);
  const [updScore, setUpdScore] = useState<number>(0);
  const [sessionId, setSessionId] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  // const [token, setToken] = useState<any>();
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const [modalData, setModalData] = useState<any | null>(null);
  const [error, setError] = useState<any | null>(null);

  const { saveAuth, setCurrentUser } = useAuth();
  const intl = useIntl();

  const {
    mutate: confirmLoginTotp,
    isLoading: isConfirmLoginTotpLoading,
    error: confirmLoginTotpError,
    data: confirmLoginTotpData,
  } = useConfirmLoginTotp();

  const loginSchema = Yup.object().shape({
    username: Yup.string().required(
      intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })
    ),

    password: Yup.string()
      .min(
        3,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
  });

  const initialValues = {
    username: ``,
    password: ``,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const params = new URLSearchParams();
        params.append("username", values.username);
        params.append("password", values.password);

        const auth = await login(params);

        if (auth?.session_id) {
          setSessionId(auth?.session_id);
          TotpConfirm(auth as ResponseTotp);
          return;
        }

        saveAuthUser(auth);
      } catch (error: any) {
        console.error(error);
        saveAuth(undefined);
        const statusText =
          (error?.response?.data?.detail?.[0]?.msg ||
            error?.response?.data?.detail) ??
          intl.formatMessage({ id: "AUTH.VALIDATION.WRONG_CREDITINALS" });
        setStatus(statusText);
        setSubmitting(false);
        setLoading(false);
        // setRefreshReCaptcha((r) => !r);
      }
    },
  });

  const saveAuthUser = async (auth: any) => {
    saveAuth(undefined);
    saveAuth(auth);
    const user = await getUserByToken();
    setCurrentUser(user);
    // setRefreshReCaptcha((r) => !r);
  };

  // useEffect(() => {
  //   return setModalData(null);
  // }, []);

  useEffect(() => {
    if (confirmLoginTotpData?.access_token) {
      setInputMaskBg(true);
      saveAuthUser(confirmLoginTotpData);
    }
  }, [confirmLoginTotpData]);

  useEffect(() => {
    if (formik?.values?.username) {
      formik.values.username = removeAllSpaces(formik?.values?.username);
    }
  }, [formik?.values?.username]);

  useEffect(() => {
    if (formik?.values?.password) {
      formik.values.password = removeAllSpaces(formik?.values?.password);
    }
  }, [formik?.values?.password]);

  const handlePassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (!regex.test(value) && value !== "") return;

    if (value.length > 6) {
      return;
    } else {
      if (error || inputMaskBg !== null) {
        // setError(null);
        setInputMaskBg(null);
      }

      const splitValue: string[] = value.split("");
      const newValues: string[] = [];

      setInputValue(value);

      for (let i = 0; i < 6; i++) {
        if (
          splitValue[i] &&
          i === splitValue.length - 1 &&
          value.length > previousValue.length
        ) {
          newValues.push(splitValue[i]);
        } else if (splitValue[i]) {
          newValues.push("*");
        } else {
          newValues.push("_");
        }
      }

      setInputMaskValues(newValues);

      if (timer) {
        clearTimeout(timer);
      }

      const newTimer = setTimeout(() => {
        if (value.length > previousValue.length) {
          setUpdScore(updScore + 1);
        }
      }, 1000);

      setIndexPass(splitValue.length - 1);
      setPreviousValue(value);
      setTimer(newTimer);
    }
  };

  useEffect(() => {
    if (indexPass > 0) {
      updInputMask();

      if (indexPass === 5) {
        const totpData: TotpLoginModel = {
          totp_code: inputValue,
          session_id: sessionId,
        };

        confirmLoginTotp(totpData, {
          onSuccess: () => {
            setError(null);
            setInputMaskBg(true);

            setTimeout(() => {
              setModalData(null);
            }, 1500);
          },
        });
      }
    }
  }, [updScore]);

  useEffect(() => {
    if (confirmLoginTotpError) {
      setError("Код введен неверно");
      handlerInvalidPassword();
    }
  }, [confirmLoginTotpError]);

  const handlerInvalidPassword = () => {
    setInputMaskBg(false);
    setInputValue("");
    setInputMaskValues(initialValuesMask);
  };

  const updInputMask = () => {
    const newValues = inputMaskValues.map((el, idx) => {
      if (idx === indexPass) {
        return (el = "*");
      }
      return el;
    });

    setInputMaskValues(newValues);
  };

  return (
    // <GoogleReCaptchaProvider
    //   reCaptchaKey={
    //     process.env.REACT_APP_GOOGLE_PUBLIC_KEY ??
    //     "6Lfmh30nAAAAAHZaOqxgTAEYSAPBHVHNYhDC2DBm"
    //   }
    // >
    <>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        {/* <LoginHeader /> */}
        {/* begin::Heading */}
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">Авторизоваться</h1>
          {/* <div className="text-gray-500 fw-semibold fs-6">
            Способ авторизации
          </div> */}
        </div>
        {/* begin::Heading */}

        {/* begin::Separator */}
        {/* <div className="separator separator-content my-14">
          <span className="w-150px text-gray-500 fw-semibold fs-7">
            Или с логином и паролем
          </span>
        </div> */}
        {/* end::Separator */}

        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="fv-row mb-8">
          <input
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.USERNAME.TITLE",
            })}
            {...formik.getFieldProps("username")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
            type="text"
            name="username"
            autoComplete="off"
          />
          {formik.touched.username && formik.errors.username && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.username}</span>
              </div>
            </div>
          )}
        </div>
        <div className="fv-row mb-3">
          <input
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.PASSWORD.TITLE",
            })}
            type="password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.password && formik.errors.password,
              },
              {
                "is-valid": formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div />

          <Link to="/auth/forgot-password" className="link-primary">
            {intl.formatMessage({ id: "AUTH.GENERAL.FORGOT_BUTTON" })}
          </Link>
        </div>

        {/* <GoogleReCaptcha
          onVerify={setToken}
          refreshReCaptcha={refreshReCaptcha}
        /> */}

        <div className="d-grid mb-10">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-primary"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "AUTH.GENERAL.SIGNUP_BUTTON" })}
              </span>
            )}

            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "GLOBAL.WAIT",
                })}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
        <div className="text-gray-500 text-center fw-semibold fs-6">
          {intl.formatMessage({ id: "AUTH.GENERAL.NO_ACCOUNT" })}{" "}
          <Link reloadDocument to="/auth/registration" className="link-primary">
            {intl.formatMessage({ id: "AUTH.GENERAL.REGISTER_BUTTON" })}
          </Link>
        </div>
      </form>

      <ModalLayout
        title={modalData?.title}
        show={Boolean(modalData)}
        handleClose={() => setModalData(null)}
      >
        <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10">
          <div className="form w-100 py-5" id="kt_sing_in_two_factor_form  ">
            <div className="text-center mb-10">
              <img
                alt="Logo"
                className="mh-125px w-100 h-100"
                src={toAbsoluteUrl("/media/svg/misc/smartphone-2.svg")}
              />
            </div>

            <div className="text-center mb-8">
              <h1 className="text-gray-900 mb-3">Two-Factor Verification</h1>
            </div>

            <div className="mb-4 w-100 ">
              <div className="fw-bold text-center text-gray-900 fs-6 mb-12 ms-1">
                Введите одноразовый код из Google аутентификатора
              </div>

              <div
                className={`${
                  inputMaskBg === false ? "shake" : ""
                } d-flex flex-wrap flex-stack justify-content-center algin-items-center mb-10`}
                style={{
                  position: "relative",
                }}
              >
                {inputMaskValues.map((value, index) => (
                  <div
                    key={index}
                    className={`btn display-3 btn-outline btn-outline-${
                      inputMaskBg === null
                        ? null
                        : inputMaskBg
                        ? "success"
                        : "danger"
                    } px-5 pt-4 pb-2 w-45px`}
                    style={{ cursor: "pointer", marginRight: "6px" }}
                  >
                    <span className="">{value}</span>
                  </div>
                ))}

                <input
                  type="number"
                  value={inputValue}
                  style={{
                    opacity: "0",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                  }}
                  className="form-control form-control-lg form-control-solid"
                  placeholder="XXX-XXX"
                  autoComplete="off"
                  // disabled={isInputPinBlocked}
                  onChange={(e) => {
                    handlePassChange(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {error && <div className="p-4 mb-5">{showError()}</div>}
      </ModalLayout>
    </>
    // </GoogleReCaptchaProvider>
  );

  function TotpConfirm(sub: ResponseTotp) {
    setModalData({
      title: "Введите ключ 2FA",
      responseData: sub,
      btnText: "Подтвердить",
      isLoading: isConfirmLoginTotpLoading,
    });
  }

  function showError() {
    return (
      <div className="fv-plugins-message-container ms-2 mb-1 text-center">
        {isArray(error?.response?.data?.detail) ? (
          error?.response?.data?.detail?.map((error: any) => (
            <div
              data-field="appname"
              data-validator="notEmpty"
              className="fv-help-block"
            >
              {error.msg}
            </div>
          ))
        ) : error?.detail ? (
          <div
            data-field="appname"
            data-validator="notEmpty"
            className="fv-help-block"
          >
            {error?.detail}
          </div>
        ) : error?.response?.data?.detail ? (
          <div
            data-field="appname"
            data-validator="notEmpty"
            className="fv-help-block"
          >
            {error?.response?.data?.detail}
          </div>
        ) : (
          <div
            data-field="appname"
            data-validator="notEmpty"
            className="fv-help-block"
          >
            {error}
          </div>
        )}
      </div>
    );
  }
}
