import { useAcceptWarningMsg, useGetMessageWarning } from "./core/_requests";

export function MessageBot() {
  const { data: messageData, refetch } = useGetMessageWarning();

  const { mutate: acceptMsg, isLoading: isAcceptMsgLoading } =
    useAcceptWarningMsg();

  return (
    <>
      {messageData &&
        messageData?.length > 0 &&
        messageData[0]?.is_read === false && (
          <>
            <div
              className="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6"
              style={{
                position: "fixed",
                zIndex: "105",
                bottom: "2%",
                left: "50%",
                transform: "translateX(-50%)",
                width: "90%",
              }}
            >
              <i className="ki-duotone ki-information fs-2tx text-warning me-4">
                <span className="path1"></span>
                <span className="path2"></span> <span className="path3"></span>
              </i>

              <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                <div className="mb-3 mb-md-0 fw-semibold">
                  <h4 className="text-gray-900 fw-bold">Технические работы</h4>

                  <div className="fs-6 text-gray-700 pe-7">
                    {messageData[0]?.description}
                  </div>
                </div>

                <a
                  href="#"
                  className="btn btn-warning px-6 align-self-center text-nowrap"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_two_factor_authentication"
                  onClick={() => {
                    acceptMsg(messageData[0]?.id, { onSuccess: refetch });
                  }}
                >
                  Принять
                </a>
              </div>
            </div>
          </>
        )}
    </>
  );
}
