import axios, { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { ID } from "../../../../../../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL;

const GET_ALL_SUBUSERS = `${API_URL}/cashback/get-all-subusers`;

const ACCOUNT_WALLET = `${API_URL}/ps3838/balance`;

const ADD_SUBUSERS = `${API_URL}/cashback/add-subuser`;

const DELETE_SUBUSERS = `${API_URL}/cashback/delete-subuser-account`;

const ACCOUNT_REGISTRATION = `${API_URL}/ps3838/login`;

export const useAllSubusers = (options: any = {}) => {
  return useQuery(
    "all-subusers",
    async () => {
      const { data } = await axios.get(GET_ALL_SUBUSERS);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useAccountWallet = () => {
  return useMutation("account-wallet", async (id: ID) => {
    const { data } = await axios.get(`${ACCOUNT_WALLET}?wallet_id=${id}`);
    return data;
  });
};

export const useAddSubusers = () => {
  return useMutation("add-subusers", async (username: { username: string }) => {
    const { data } = await axios.post(ADD_SUBUSERS, username);
    return data;
  });
};

export const useDeleteSubusers = () => {
  return useMutation("delete-subusers", async (id: { user_id: ID }) => {
    const { data } = await axios.delete(`${DELETE_SUBUSERS}/${id.user_id}`);
    return data;
  });
};

export const useRegistrationAccount = () => {
  return useMutation(
    "account-registration",
    async (amount: { amount: number }) => {
      const { data } = await axios.post(ACCOUNT_REGISTRATION, amount);
      return data;
    }
  );
};
