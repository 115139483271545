import { Footer } from "./Footer";

const FooterWrapper = () => {
  return (
    <div className="mt-10">
      <Footer />
    </div>
  );
};

export { FooterWrapper };
