import { useState } from "react";

import { useIntl } from "react-intl";
import { KTIcon } from "../../../../_metronic/helpers";
import { ModalLayout } from "../../../../_metronic/partials/modals/ModalLayout";
import { useAuth } from "../core/Auth";

export const LogoutBtn = () => {
  const intl = useIntl();

  const { logout } = useAuth();

  const [modalData, setModalData] = useState<any | null>(null);

  const setModalLogout = () => {
    setModalData({
      title: "Выход",
      desc: "Вы действительно хотите выйти?",
      action: () => logout(),
      btnTrueName: "Да",
      btnFalseName: "Нет",
    });
  };

  return (
    <>
      <button className="btn btn-sm btn-danger " onClick={setModalLogout}>
        <KTIcon iconName="shield-cross" className="fs-4 mb-1" />
        <span>Выйти</span>
      </button>

      <ModalLayout
        title={modalData?.title}
        show={Boolean(modalData)}
        handleClose={() => setModalData(null)}
      >
        {modalData?.desc && (
          <div className="mb-12 fs-md-3 fs-7 fw-bold text-center">
            {modalData?.desc}
          </div>
        )}

        <div className="p-2 d-flex justify-content-center align-items-center">
          <button
            type="button"
            disabled={modalData?.isLoading}
            className="d-block me-5 me-sm-20 btn btn-success btn-sm px-10 px-sm-15"
            onClick={() => {
              modalData?.action();
              setModalData(null);
            }}
          >
            {modalData?.isLoading && (
              <span
                className="spinner-border spinner-border-sm me-4"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {modalData?.btnTrueName}
          </button>

          <button
            type="button"
            className="d-block btn btn-danger btn-sm px-8 px-sm-15"
            onClick={() => {
              setModalData(null);
            }}
          >
            {modalData?.btnFalseName}
          </button>
        </div>
      </ModalLayout>
    </>
  );
};
