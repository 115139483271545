import React, { FC, createContext, useEffect, useState } from "react";
import {
  useChangeUserSettings,
  useFreeWinLogin,
  useGetUser,
} from "./_requests";
import { WithChildren } from "../../../../_metronic/helpers";
import { SelectAccount, TotpStatus } from "../../../../api/core-contract";

interface Context {
  TotpStatus?: TotpStatus | null;
  updateTotpStatus: (TotpStatus: any) => void;
}

export const UserContext = createContext<Context | any | null>(null);

export const UserContextProvider: FC<WithChildren> = ({ children }) => {
  // const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [modalMode, setModalMode] = useState<string | undefined>(undefined);
  const [selectAccount, setSelectAccount] = useState<SelectAccount | null>(
    null
  );
  const [selectUsernameAccount, setSelectUsernameAccount] = useState<
    string | null
  >(null);

  const {
    data: userData,
    isFetching: isUserDataFetching,
    refetch: userDataRefetch,
  } = useGetUser();

  const { mutate: changeUserSettings, isLoading: isChangeUserSettingsLoading } =
    useChangeUserSettings();

  const [TotpStatus, setTotpStatus] = useState<boolean | undefined | null>(
    false
  );
  const [locale, setLocale] = useState<string | undefined | null>(null);
  const [view, setView] = useState<string | undefined | null>(null);

  useEffect(() => {
    if (userData) {
      setTotpStatus(userData?.settings?.totp ?? null);

      setLocale(userData?.settings?.locale ?? null);
      setView(userData?.settings?.view ?? null);

      // setIsUserDataLoaded(true);
    }
  }, [userData]);

  const resetTotpStatus = (newTotpStatus: boolean) => {
    setTotpStatus(newTotpStatus);

    changeUserSettings({
      settings: {
        locale: locale,
        view: view,
        totp: newTotpStatus,
      },
    });
  };

  const updateLocaleView = (newLocale: string, newView: string) => {
    setLocale(newLocale);
    setView(newView);

    changeUserSettings({
      settings: {
        locale: newLocale,
        view: newView,
        totp: TotpStatus,
      },
    });
  };

  return (
    <UserContext.Provider
      value={{
        locale,
        view,
        updateLocaleView,
        userDataRefetch,

        TotpStatus,
        setTotpStatus,
        resetTotpStatus,

        isUserDataFetching,
        isChangeUserSettingsLoading,
        // isUserDataLoaded,
        modalMode,
        setModalMode,

        selectAccount,
        setSelectAccount,

        selectUsernameAccount,
        setSelectUsernameAccount,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
