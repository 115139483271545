/* eslint-disable react-hooks/exhaustive-deps */
import {
  FC,
  useContext,
  useState,
  useEffect,
  useMemo,
  createContext,
} from "react";
import { useAllSubusers } from "./_requests";
import { useQueryRequest } from "./QueryRequestProvider";
import {
  WithChildren,
  stringifyRequestQuery,
} from "../../../../../../../../_metronic/helpers";
import { SubuserAccountsData } from "./_models";

interface AccountContext {
  AllAccountsDataLoading: boolean;
  refetchAllAccountsData: () => void;
  response?: SubuserAccountsData[];
  query?: string;
}

const initialQueryState = {
  AllAccountsDataLoading: false,
  refetchAllAccountsData: () => {},
  response: undefined,
};

export const QueryResponseContext =
  createContext<AccountContext>(initialQueryState);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));

  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    data: response,
    isLoading: isAllAccountsFetching,
    refetch: allAccountsRefetch,
  } = useAllSubusers({ refetchInterval: 15000 });

  const AllAccountsDataLoading = isAllAccountsFetching;

  const refetchAllAccountsData = () => {
    allAccountsRefetch();
  };

  return (
    <QueryResponseContext.Provider
      value={{
        AllAccountsDataLoading,
        refetchAllAccountsData,
        response,
        query,
      }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response || [];
};

const useQueryResponsePagination = () => {
  // const defaultPaginationState: PaginationState = {
  //   links: [],
  //   ...initialQueryState,
  // };

  // const { response } = useQueryResponse();
  // if (!response || !response.payload || !response.payload.pagination) {
  //   return defaultPaginationState;
  // }
  return undefined;

  // return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { AllAccountsDataLoading } = useQueryResponse();
  return AllAccountsDataLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};
