import clsx from "clsx";
import { toAbsoluteUrl } from "../../../helpers";
import { HeaderUserMenu } from "../../../partials";
import { useAuth } from "../../../../app/modules/auth";
import { useIntl } from "react-intl";
import { NavLink } from "../../../../app/pages/main-page/components/NavLink";
import { LogoutBtn } from "../../../../app/modules/auth/components/LogoutBtn";
import { CashbackLogo } from "../../../../app/pages/main-page/components/CashbackLogo";

const itemClass = "ms-1 ms-lg-3";
// const btnClass =
// "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";

const Navbar = () => {
  const { currentUser } = useAuth();
  const intl = useIntl();

  if (!currentUser) {
    return (
      <div className="ms-auto">
        <NavLink
          title={
            <div className="h-100 fw-bold fs-6 text-cashback d-flex align-items-center">
              {intl.formatMessage({ id: "MAIN.PAGE.LOGIN.TITLE" })}
            </div>
          }
          to="/auth"
        />
      </div>
    );
  }

  return (
    <div className="app-navbar w-100  flex-shrink-0 d-flex gap-2 h-md-50 my-auto">
      <div className="d-flex align-items-center justify-content-between w-100">
        <NavLink title={<CashbackLogo />} to="/" />
        <LogoutBtn />
      </div>
    </div>
  );
};

export { Navbar };
