import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { Avatar } from "../ProfileModels";
import {
  UserWallet,
  SubusersPostTransBalance,
  TronAddress,
  GetTransaction,
  TransactionHistory,
  FreeWinURL,
  UserSettingsSchemas,
  FreeWinLocale,
  ShowUserForUser,
  Ps3838TransBalance,
  BodyWithdrawFromFreewinApiBanksWithdrawPost,
  BodyWithdrawFromWithdraw,
} from "../../../../api/core-contract";
import { GetCashbackReqData, WithdrawCashbackReqData } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

const GET_TRON_WALLET = `${API_URL}/tron/wallet`;

const SET_DEPOSIT_SUBUSERS = `${API_URL}/cashback/deposit-subuser`;

const WITHDRAW_DEPOSIT_FREEWIN = `${API_URL}/freewin/withdraw`;

const WITHDRAW_DEPOSIT_PS3838 = `${API_URL}/ps3838/withdraw`;

const FREEWIN_LOGIN_RU = `${API_URL}/freewin/login_ru`;

const GET_ALL_AVATARS = `${API_URL}/settings/avatar/all`; // Показать доступные аватары
const CHOOSE_AVATAR = `${API_URL}/settings/avatar/choose_avatar`; // Выбрать аватар

const MY_BETS = `${API_URL}/freewin/my-bets`;

const WALLET_VERIFICATION = `${API_URL}/wallet/verification`;

const RESET_TOTP_URL = `${API_URL}/totp/reset`;

const USER_DATA = `${API_URL}/user`;

const CHANGE_USER_DATA = `${API_URL}/user/settings`;

const WITHDRAW_DEPOSIT_BANKS = `${API_URL}/freewin/withdraw/banks`;

const WITHDRAW_CASHBACK_REQUEST = `${API_URL}/cashback/withdraw-balance`;

const GET_CASHBACK = `${API_URL}/cashback/get-cashback`;

const WITHDRAW_TRON = `${API_URL}/cashback/withdraw/external`;

const GET_WALLET = `${API_URL}/user/balance`;

const GET_WALLET_TRANSACTION = `${API_URL}/cashback/statistic/incoming-trans`;

const GET_TOTP_SECRET_CODE = `${API_URL}/totp_v2/qrcode/create`;

const CONFIRM_TOTP = `${API_URL}/totp_v2/qrcode/verify`;

const ENABLE_TOTP = `${API_URL}/totp_v2/totp/enable`;

const DISABLE_TOTP = `${API_URL}/totp_v2/totp/disable`;

const REQUEST_PASSWORD_URL = `${API_URL}/login/reset/password`;

export const useAllAvatar = () => {
  return useMutation({
    mutationFn: () => {
      return axios.get(GET_ALL_AVATARS);
    },
  });
};

export const useMyBets = (options: any = {}) => {
  return useQuery<FreeWinURL>(
    "my-bets",
    async () => {
      const { data } = await axios.get(MY_BETS);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useWalletVerification = (options: any = {}) => {
  return useQuery<FreeWinURL>(
    "wallet_verification",
    async () => {
      const { data } = await axios.get(WALLET_VERIFICATION);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useChooseAvatar = () => {
  return useMutation<any, any, Avatar>({
    mutationFn: (body) => {
      return axios.put(CHOOSE_AVATAR, body);
    },
  });
};

export const useWallet = (options: any = {}) => {
  return useQuery<UserWallet>(
    "wallet",
    async () => {
      const { data } = await axios.get(GET_WALLET);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useTronWallet = (options: any = {}) => {
  return useQuery<TronAddress>(
    "tron-wallet",
    async () => {
      const { data } = await axios.get(GET_TRON_WALLET);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useWalletTransactions = (options: any = {}) => {
  return useMutation<{ data: TransactionHistory[] }, any, GetTransaction>({
    mutationFn: async (data) => {
      const response = await axios.post(GET_WALLET_TRANSACTION, data);
      return {
        data: response.data,
      };
    },
  });
};

export const useDepositSubusers = () => {
  return useMutation<SubusersPostTransBalance, any, SubusersPostTransBalance>(
    "deposit-subusers",
    async (sendDep) => {
      const { data: response } = await axios.post(
        SET_DEPOSIT_SUBUSERS,
        sendDep
      );
      return response;
    }
  );
};

export const useWithdrawDepositFreewin = () => {
  return useMutation<any, BodyWithdrawFromWithdraw, any>(
    "withdraw-deposit",
    async (sendWithdrawData: BodyWithdrawFromWithdraw) => {
      const { data: response } = await axios.post(
        WITHDRAW_DEPOSIT_FREEWIN,
        sendWithdrawData
      );
      return response;
    }
  );
};

export const useWithdrawDepositBanks = () => {
  return useMutation<any, BodyWithdrawFromFreewinApiBanksWithdrawPost, any>(
    "withdraw-deposit-banks",
    async (sendWithdrawData: BodyWithdrawFromFreewinApiBanksWithdrawPost) => {
      const { data: response } = await axios.post(
        WITHDRAW_DEPOSIT_BANKS,
        sendWithdrawData
      );
      return response;
    }
  );
};

export const useWithdrawDepositPs3838 = () => {
  return useMutation<any, Ps3838TransBalance, any>(
    "withdraw-deposit",
    async (sendWithdrawData: Ps3838TransBalance) => {
      const { data: response } = await axios.post(
        WITHDRAW_DEPOSIT_PS3838,
        sendWithdrawData
      );
      return response;
    }
  );
};

export const useFreeWinLogin = () => {
  return useMutation<FreeWinURL, FreeWinLocale, FreeWinLocale>(
    "freeWin-Login",
    async (body) => {
      const { data } = await axios.post(FREEWIN_LOGIN_RU, body);
      return data;
    }
  );
};

export const useVerifyTOTP = () => {
  return useMutation("verify-totp", async (token: string) => {
    const { data: response } = await axios.post(
      `${CONFIRM_TOTP}?code=${token}`
    );
    return response as any;
  });
};

export const useEnableTOTP = () => {
  return useMutation("enable-totp", async (token: string) => {
    const { data: response } = await axios.post(
      `${ENABLE_TOTP}?totp_code=${token}`
    );
    return response as any;
  });
};

export const useDisableTOTP = () => {
  return useMutation("disable-totp", async (token: string) => {
    const { data: response } = await axios.post(
      `${DISABLE_TOTP}?totp_code=${token}`
    );
    return response as any;
  });
};

export const useTOTPResetUrl = () => {
  return useMutation({
    mutationFn: () => {
      return axios.put(RESET_TOTP_URL);
    },
  });
};

export const useGetUser = (options: any = {}) => {
  return useQuery<ShowUserForUser>(
    "user-data",
    async () => {
      const { data } = await axios.get(USER_DATA);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useChangeUserSettings = () => {
  return useMutation<UserSettingsSchemas, any, ShowUserForUser>({
    mutationFn: (body) => {
      return axios.put(CHANGE_USER_DATA, body);
    },
  });
};

export const useGetTOTPSecretCode = () => {
  return useMutation("totp-secret-code", async () => {
    const { data: response } = await axios.get(GET_TOTP_SECRET_CODE);
    return response;
  });
};

export const useWithdrawalRequest = () => {
  return useMutation(
    "withdrawal-request",
    async (sendData: WithdrawCashbackReqData) => {
      const { data: response } = await axios.post(
        WITHDRAW_CASHBACK_REQUEST,
        sendData
      );
      return response;
    }
  );
};

export const useGetCashback = () => {
  return useMutation("get-cashback", async (sendData: GetCashbackReqData) => {
    const { data: response } = await axios.post(GET_CASHBACK, sendData);
    return response;
  });
};

export const useWithdrawTron = () => {
  return useMutation<any, BodyWithdrawFromWithdraw, any>(
    "withdraw-tron",
    async (sendWithdrawData: BodyWithdrawFromWithdraw) => {
      const { data: response } = await axios.post(
        `${WITHDRAW_TRON}${
          sendWithdrawData?.totp_code
            ? `?totp_code=${sendWithdrawData?.totp_code}`
            : ""
        }`,
        sendWithdrawData.send_data
      );
      return response;
    }
  );
};

export const useResetPassword = () => {
  return useMutation(
    "reset-pass",
    async (reqData: { new_password: string; totp_code?: string }) => {
      const { data } = await axios.post(REQUEST_PASSWORD_URL, reqData);
      return data;
    }
  );
};
