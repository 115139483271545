import type { FC } from "react";
import React from "react";

interface Props {}

export const CashbackLogo: FC<Props> = React.memo(() => {
  return (
    <span
      className="text-cashback  text-uppercase fw-bolder fst-italic fs-3x"
      style={{ fontFamily: "Open Sans" }}
    >
      cashback
    </span>
  );
});
