import type { FC } from "react";
import React from "react";
import { useGetFaq } from "./_requests";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

interface Props {}

export const Faq: FC<Props> = React.memo(() => {
  const { data, isLoading } = useGetFaq();

  const navigate = useNavigate();

  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      <div className="card">
        <div className="card-body p-lg-15 p-5">
          <div className="mb-13">
            <div className="mb-15">
              <div className="d-flex align-items-center justify-content-between w-100">
                <h4 className="fs-2x text-gray-800 w-bolder mb-6">
                  Часто задаваемые вопросы
                </h4>
                <button
                  className="btn btn-sm btn-primary mb-10"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  На главную
                </button>
              </div>
              <p className="fw-semibold fs-4 text-gray-600 mb-2">
                На этой странице вы найдете ответы на наиболее популярные
                вопросы наших клиентов. Здесь собраны решения самых
                распространенных проблем и вопросов по работе с нашим сервисом.
              </p>
            </div>
            <div className="row mb-12">
              {data?.map((item) => (
                <div className="col-md-6 pe-md-10 mb-10 mb-10" key={item.id}>
                  <h2 className="text-gray-800 fw-bold mb-4">{item.name}</h2>

                  {item?.faq_category?.map((category) => (
                    <div className="m-0" key={category.id}>
                      <div
                        className="d-flex align-items-center collapsible collapsed py-3 toggle mb-0"
                        data-bs-toggle="collapse"
                        data-bs-target={`#kt_job_${item.id}_${category.id}`}
                      >
                        <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                          <i className="ki-duotone ki-minus-square toggle-on text-primary fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          <i className="ki-duotone ki-plus-square toggle-off fs-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            <span className="path3"></span>
                          </i>
                        </div>
                        <h4
                          className="text-gray-700 fw-bold cursor-pointer mb-0"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {category?.question}
                        </h4>
                      </div>
                      <div
                        id={`kt_job_${item.id}_${category.id}`}
                        className="collapse fs-6 ms-1"
                      >
                        <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                          <span
                            className={styles["faq-custom"]}
                            style={{ whiteSpace: "pre-line" }}
                            dangerouslySetInnerHTML={{
                              __html: category?.answer,
                            }}
                          ></span>
                        </div>
                      </div>
                      <div className="separator separator-dashed"></div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
