/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ConfirmRegistration } from "./components/ConfirmRegistration";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

import { Login } from "./components/Login";
import { ConfirmPassword } from "./components/ConfirmPassword";
import { ForgotPassword } from "./components/ForgotPassword";
import { NotificationRegistrationEditModal } from "./components/NotificationRegistrationEditModal";
import { MessageBot } from "../../../_metronic/layout/components/message-bot/MessageBot";

export const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
      {/* begin::Body */}
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
        {/* begin::Form */}
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
          {/* begin::Wrapper */}
          <div className="w-lg-500px p-10">
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/misc/auth-bg.png")})`,
        }}
      >
        {/* begin::Content */}
        <div className="d-flex flex-column flex-center p-2 px-md-15 w-100">
          {/* begin::Logo */}

          <span
            className="text-cashback  text-uppercase fw-bolder fst-italic fs-6x"
            style={{ fontFamily: "Open Sans" }}
          >
            cashback
          </span>

          {/* end::Logo */}

          {/* begin::Image */}
          <img
            className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
            src={toAbsoluteUrl("/media/misc/auth-screens.png")}
            alt=""
          />
          {/* end::Image */}

          {/* begin::Title */}
          {/* <h1 className="text-white fs-2qx fw-bolder text-center mb-7">
            Fast, Efficient and Productive
          </h1> */}
          {/* end::Title */}

          {/* begin::Text */}
          {/* <div className="text-white fs-base text-center">
            In this kind of post,{" "}
            <a href="#" className="opacity-75-hover text-warning fw-bold me-1">
              the blogger
            </a>
            introduces a person they’ve interviewed <br /> and provides some
            background information about
            <a href="#" className="opacity-75-hover text-warning fw-bold me-1">
              the interviewee
            </a>
            and their <br /> work following this is a transcript of the
            interview.
          </div> */}
          {/* end::Text */}
          <MessageBot />
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />

      <Route path="registration">
        <Route index element={<Registration />} />
        <Route path=":refLinkParams">
          <Route index element={<Registration />} />
          <Route
            path="notification"
            element={<NotificationRegistrationEditModal />}
          />
          <Route path="confirm" element={<ConfirmRegistration />} />
        </Route>
        <Route
          path="notification"
          element={<NotificationRegistrationEditModal />}
        />
        <Route path="confirm" element={<ConfirmRegistration />} />
      </Route>

      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/*" element={<ConfirmPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
